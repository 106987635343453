import { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { logEvent } from "firebase/analytics";
import { analytics, db } from "./firebaseConfig";

function App() {
  logEvent(analytics, "app_opened");

  function openModal() {
    const modal = document.getElementById("my_modal");
    (modal as HTMLDialogElement)?.showModal();
    logEvent(analytics, "modal_opened", { location: "waitlist_modal" });
  }

  function closeModal() {
    const modal = document.getElementById("my_modal");
    (modal as HTMLDialogElement)?.close();
  }
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Function to handle email submission
  const handleSaveEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    setSuccess(false);
    logEvent(analytics, "email_try_submit", { email });

    if (!email) {
      setError("Please enter an email.");
      return;
    }

    if (!validateEmail(email)) {
      setError("Please enter a valid email.");
      return;
    }

    try {
      await addDoc(collection(db, "emails"), { email });
      setEmail("");
      setError("");
      setSuccess(true);
      logEvent(analytics, "email_submitted", { email });
    } catch (error) {
      console.error("Error adding email:", error);
      setError("Failed to save email. Please try again.");
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-purple-100 to-blue-100 flex flex-col items-center justify-between py-4 px-7">
      {/* Top Bar */}
      <div className="w-full flex justify-between items-center">
        <img src="/yocial-logo3.png" alt="Logo" className="w-20" />
        <button onClick={openModal} className="btn btn-neutral text-white">
          Sign up to waitlist
        </button>
      </div>

      {/* Main Content */}
      <div className="flex flex-col md:flex-row items-center justify-center mt-12">
        {/* Text Section */}
        <div className="text-center md:text-left md:w-1/2 space-y-6">
          <h1 className="text-5xl font-extrabold text-gray-800">
            Become the social version of{" "}
            <span className="bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
              yourself
            </span>
          </h1>
          <p className="text-gray-600 text-lg">
            Practice your social skills in a safe environment with realtime AI
            avatars. Master any social situation with confidence, never run out
            of things to say, and learn to be charismatic naturally.
          </p>
          <div className="flex justify-center md:justify-start space-x-4">
            <button onClick={openModal} className="btn btn-neutral text-white">
              Sign up to waitlist
            </button>
          </div>
        </div>

        {/* Phone Mockups */}
        <div className="flex flex-col items-center mt-12 md:mt-0 md:ml-12">
          <img
            src="/photo.png"
            alt="Phone 1"
            className="w-52 rounded-3xl shadow-lg"
          />
          <div className="italic">Practice with AI-Avatars!</div>
        </div>
      </div>

      {/* Footer */}
      <div className="w-full flex justify-center items-center mt-8 space-x-2 text-gray-600">
        <span>Made with ❤️ in Munich, Germany!</span>
      </div>

      {/* Modal */}
      <dialog id="my_modal" className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <button
            onClick={closeModal}
            className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
          >
            ✕
          </button>
          <h3 className="font-bold text-lg">Sign up to waitlist</h3>
          <p className="py-4">Enter your email below to join the waitlist.</p>
          <input
            type="email"
            placeholder="E-Mail"
            className="input input-bordered w-full mb-2"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          {error && <p className="text-red-500 mb-4">{error}</p>}
          {success && (
            <div className="flex flex-col items-center">
              <img
                src="/success.webp"
                alt="Success"
                className="w-3/4 rounded-3xl shadow-lg"
              />
              <p className="text-green-700 my-3 text-2xl font-bold text-center">
                You are on the waitlist!
              </p>
            </div>
          )}
          <div className="modal-action">
            <form method="dialog" onSubmit={handleSaveEmail}>
              <button type="submit" className="btn btn-neutral text-white">
                Save
              </button>
            </form>
          </div>
        </div>
      </dialog>
    </div>
  );
}

export default App;
